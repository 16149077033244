import React from "react";
import { Dialog, DialogTitle } from "@headlessui/react";
import { Button } from "@/components/ui/button";

interface SettingsModalProps {
  onClose: () => void;
}

export default function SettingsModal({ onClose }: SettingsModalProps) {
  return (
    <Dialog open onClose={onClose} className="fixed z-50 inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4">
        <div className="fixed inset-0 bg-black opacity-30" />
        <div className="relative bg-white rounded max-w-md mx-auto p-6 z-10">
          <DialogTitle className="text-black font-inter text-lg font-medium mb-7">Settings</DialogTitle>
          <div className="space-y-4">
            <a
              href="/api/google/auth"
              className="flex items-center text-sm text-dark_green_bg hover:underline no-underline font-inter"
            >
              <span>Grant Access to Google Calendar</span>
              <img src="/link.svg" alt="External link" className="w-2 h-2 ml-2" />
            </a>
          </div>
          <div className="flex justify-left mt-6">
            <Button
              onClick={onClose}
              className="bg-lightest_grey hover:bg-gray-200 text-[14px] text-black font-normal font-inter"
            >
              Close
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
